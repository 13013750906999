import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';
import {
  AsyncCompaniesPage,
  AsyncCompareOurProductsPage,
  AsyncHowWeDoItPage,
  AsyncLoginPage,
  AsyncMyBriefingsPage,
  AsyncMyNewsPage,
  AsyncRequestDemoPage,
  AsyncSearchPage,
  AsyncSettingsPage,
  AsyncTeamPage,
  AsyncWhatWeDoPage,
  AsyncWhyBriefcasePage,
  AsyncDashboardPage
} from '../../App';
import { APP_ROUTES } from '../../constants/routes';
import { isUnauthorizedRoute as isUnauthorizedLocation } from '../../utils';
import ContactUsModal from '../ContactUsModal';
import Button from '../common/Button';
import Container, { ContainerWithPaddings } from '../common/Container';
import { media } from '../../utils/styleUtils';
import MobileMenu from './MobileMenu';
import { toggleContactUsModal } from '../../actions/app';
import { logout } from '../../actions/auth';

const NavBarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.headerDarkBg};
`;
const FlexMenuRight = styled(Flex)`
  ${media.sm`
    display: none;
  `}
`;
const MobileTrigger = styled.div`
  display: none;
  ${(props) => props.isUnauthorizedRoute
    && media.sm`
    display: flex;
    align-items: center;
    padding: 0 29px;
    background-color: ${props.theme.color.headerBg};
    color: ${props.theme.color.fontOnDarkBgColor};
    font-size: 14px;
  `};
`;
const Logo = styled.img`
  height: 20px;
  margin: 25px 17px 25px 0;
  ${media.sm`
    margin-left: 17px;
  `}
`;
const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  min-width: 200px;
`;
const MenuItem = styled(NavLink)`
  display: inline-block;
  margin: 0 22px;
  padding: 26px 20px 24px;
  color: ${(props) => props.theme.color.fontOnDarkBgColor};
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  :hover,
  &.active {
    background-color: ${(props) => props.theme.color.headerBg};
  }
  ${media.sm`
    display: none;
  `}
`;

const MenuItemIcon = styled(({
  icon, disabled, last, children, ...rest
}) => (
  <MenuItem {...rest}>{children}</MenuItem>
))`
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  align-items: center;
  position: relative;
  margin-left: 10px;
  margin-right: ${({ last }) => (last ? '0' : '10px')};
  padding-left: 60px;
  :before {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    content: "";
    width: 30px;
    height: 30px;
    background-image: url('/images/icons/${({ icon }) => icon}-on.svg');
    background-repeat: no-repeat;
    background-position: center;
    ${({ last, icon }) => {
    let size = 32;
    if (last) size = 34;
    if (icon === 'icon-line-chart') size = 20;
    return `background-size: ${size}px;`;
  }}
    border: ${({ last }) => (last ? 'none' : '1px solid #fff')};
    border-radius: 50%;
  }
  :hover:before,
  &.active:before {
    width: 32px;
    height: 32px;
    border: none;
    background-color: #fff;
    background-image: url('/images/icons/${({ icon }) => icon}-off.svg');
    background-size: ${({ icon }) => (icon === 'icon-line-chart' ? '24px' : '34px')};
  }
  ${media.lg`
    margin-left: 0;
    margin-right: 0;
    padding-left: 50px;
    padding-right: 10px;
    :before {
      left: 10px;
    }
  `}
  ${media.md`
    margin-left: 0;
    margin-right: 0;
    padding-left: 30px;
    padding-right: 5px;
    font-size: 14px;
    :before {
      left: 5px;
      width: 20px;
      height: 20px;
      background-size: 22px;
    }
    :hover:before,
    &.active:before {
      width: 22px;
      height: 22px;
      background-size: 24px;
    }
  `}
`;
const LoginLink = styled.a`
  height: auto;
  margin-right: 20px;
  font-size: 14px;
  display: block;
  align-self: center;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: ${(props) => props.theme.color.fontOnDarkBgColor};
`;
const SubMenuWrapper = styled(Flex)`
  background-color: ${(props) => props.theme.color.headerBg};
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  ${media.sm`
    display: none;
  `}
`;
const SubMenuContainer = styled.div`
  display: ${(props) => (props.isUnauthorizedRoute ? 'flex' : 'none')};
  justify-content: center;
`;
const SubMenuItem = styled(NavLink)`
  position: relative;
  padding: 13px 15px;
  line-height: 20px;
  color: ${(props) => props.theme.color.fontOnDarkBgColor};
  font-size: 16px;
  text-decoration: none;
  outline: none;
  :hover:after,
  &.active:after {
    position: absolute;
    left: 15px;
    bottom: 0;
    width: calc(100% - 30px);
    height: 3px;
    content: '';
    background-color: ${(props) => props.theme.color.fontOnDarkBgColor};
  }
`;

class NavBar extends Component {
  state = {
    mobileMenuVisible: false,
  };

  onMouseOver = (AsyncComponent) => () => {
    AsyncComponent.preload();
  };

  toggleMobileMenu = () => this.setState((state) => ({
    mobileMenuVisible: !state.mobileMenuVisible,
  }));

  render() {
    const { data } = this.props.account;
    const isUnauthorizedRoute = isUnauthorizedLocation();
    const hideDashboardIcon = !data.dashboardSettings.enabled;

    return (
      <>
        <NavBarWrapper>
          <ContactUsModal
            isOpen={this.props.isModalOpened}
            title="Contact us"
            onClose={this.props.toggleContactUsModal}
          />
          <MenuWrapper>
            <Container>
              <Flex alignItems="stretch" justifyContent={isUnauthorizedRoute ? 'space-between' : ['center', 'center', 'space-between']}>
                <Flex alignItems="stretch">
                  <LogoLink to={APP_ROUTES.base} onMouseOver={this.onMouseOver(AsyncWhyBriefcasePage)} onFocus={this.onMouseOver(AsyncWhyBriefcasePage)}>
                    <Logo src="/images/logo.svg" />
                  </LogoLink>
                  {/* { */}
                  {/*  this.props.isAuthenticated */}
                  {/*  && ( */}
                  {/*    <> */}
                  {/*      <FlexMenuRight> */}
                  {/*        <MenuItemIcon */}
                  {/*          id="my-news-menu" */}
                  {/*          icon="icon-mynews" */}
                  {/*          to={APP_ROUTES.myNews} */}
                  {/*          onMouseOver={this.onMouseOver(AsyncMyNewsPage)} */}
                  {/*          onFocus={this.onMouseOver(AsyncMyNewsPage)} */}
                  {/*        > */}
                  {/*          My&nbsp;News */}
                  {/*        </MenuItemIcon> */}
                  {/*        <MenuItemIcon */}
                  {/*          id="briefings-menu" */}
                  {/*          icon="icon-briefings" */}
                  {/*          to={APP_ROUTES.myBriefingsBase} */}
                  {/*          onMouseOver={this.onMouseOver(AsyncMyBriefingsPage)} */}
                  {/*          onFocus={this.onMouseOver(AsyncMyBriefingsPage)} */}
                  {/*        > */}
                  {/*          Briefings */}
                  {/*        </MenuItemIcon> */}
                  {/*        <MenuItemIcon */}
                  {/*          id="companies-menu" */}
                  {/*          icon="icon-companies" */}
                  {/*          to={APP_ROUTES.companiesBase} */}
                  {/*          onMouseOver={this.onMouseOver(AsyncCompaniesPage)} */}
                  {/*          onFocus={this.onMouseOver(AsyncCompaniesPage)} */}
                  {/*        > */}
                  {/*          Companies */}
                  {/*        </MenuItemIcon> */}
                  {/*        <MenuItemIcon */}
                  {/*          icon="icon-line-chart" */}
                  {/*          disabled={hideDashboardIcon} */}
                  {/*          to={APP_ROUTES.dashboard} */}
                  {/*          key="dashboard" */}
                  {/*          onMouseOver={this.onMouseOver(AsyncDashboardPage)} */}
                  {/*          onFocus={this.onMouseOver(AsyncDashboardPage)} */}
                  {/*        > */}
                  {/*          Dashboard */}
                  {/*        </MenuItemIcon> */}
                  {/*        <MenuItemIcon */}
                  {/*          id="search-menu" */}
                  {/*          icon="icon-search" */}
                  {/*          to={APP_ROUTES.search} */}
                  {/*          onMouseOver={this.onMouseOver(AsyncSearchPage)} */}
                  {/*          onFocus={this.onMouseOver(AsyncSearchPage)} */}
                  {/*        > */}
                  {/*          Search */}
                  {/*        </MenuItemIcon> */}
                  {/*      </FlexMenuRight> */}
                  {/*    </> */}
                  {/*  ) */}
                  {/* } */}
                </Flex>
                <FlexMenuRight alignItems="stretch">
                  {this.props.isAuthenticated ? [
                    <LoginLink
                      onClick={this.props.logout}
                      href={process.env.REACT_APP_LOGIN_LINK}
                      key="login"
                      onMouseOver={this.onMouseOver(AsyncLoginPage)}
                      onFocus={this.onMouseOver(AsyncLoginPage)}
                    >
                      Login
                    </LoginLink>,
                  ] : [
                    <LoginLink
                      onClick={this.props.logout}
                      href={process.env.REACT_APP_LOGIN_LINK}
                      key="login"
                      onMouseOver={this.onMouseOver(AsyncLoginPage)}
                      onFocus={this.onMouseOver(AsyncLoginPage)}
                    >
                      Login
                    </LoginLink>,
                    <Flex alignItems="center" key="requestDemo">
                      <Link to={APP_ROUTES.requestADemo} onMouseOver={this.onMouseOver(AsyncRequestDemoPage)} onFocus={this.onMouseOver(AsyncRequestDemoPage)}>
                        <Button primary small>Request a demo</Button>
                      </Link>
                    </Flex>
                  ]}
                </FlexMenuRight>
                <MobileTrigger
                  isUnauthorizedRoute={isUnauthorizedRoute}
                  onClick={this.toggleMobileMenu}
                >
                  Menu
                </MobileTrigger>
              </Flex>
            </Container>
          </MenuWrapper>
          {
            this.state.mobileMenuVisible
            && (
            <MobileMenu
              isAuthenticated={this.props.isAuthenticated}
              toggleMobileMenu={this.toggleMobileMenu}
              toggleModal={this.props.toggleContactUsModal}
              onMouseOver={this.onMouseOver}
            />
            )
          }
        </NavBarWrapper>
        <SubMenuContainer
          justifyContent="center"
          isUnauthorizedRoute={isUnauthorizedRoute}
        >
          <Box pt={[0, 0, 46]} />
          <SubMenuWrapper>
            <ContainerWithPaddings>
              <Flex justifyContent="space-between">
                <SubMenuItem
                  onMouseOver={this.onMouseOver(AsyncWhyBriefcasePage)}
                  onFocus={this.onMouseOver(AsyncWhyBriefcasePage)}
                  to={APP_ROUTES.base}
                  exact
                >
                  Why Briefcase
                </SubMenuItem>
                <SubMenuItem onMouseOver={this.onMouseOver(AsyncWhatWeDoPage)} to={APP_ROUTES.whatWeDo}>What we do</SubMenuItem>
                <SubMenuItem onMouseOver={this.onMouseOver(AsyncHowWeDoItPage)} to={APP_ROUTES.howWeDoIt}>How we do it</SubMenuItem>
                <SubMenuItem onMouseOver={this.onMouseOver(AsyncCompareOurProductsPage)} to={APP_ROUTES.compareOurProducts}>Products</SubMenuItem>
                <SubMenuItem onMouseOver={this.onMouseOver(AsyncTeamPage)} to={APP_ROUTES.team}>Team</SubMenuItem>
                <SubMenuItem onMouseOver={this.onMouseOver(AsyncTeamPage)} to={APP_ROUTES.news}>News</SubMenuItem>
                <SubMenuItem to="#" onClick={this.props.toggleContactUsModal}>Contact us</SubMenuItem>
              </Flex>
            </ContainerWithPaddings>
          </SubMenuWrapper>
        </SubMenuContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  isModalOpened: state.app.isContactUsOpened
});

export default connect(
  mapStateToProps,
  { toggleContactUsModal, logout },
  null,
  { pure: false }
)(NavBar);
